<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
    transition="dialog-bottom-transition"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Printing details</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-575px scroll-y mx-5 mx-xl-15 py-7"
      >
        <h3 class="text-center">{{ packingResponse.message }}</h3>
        <!--begin::Body-->
        <div
          :class="[
            'row my-5',
            { 'justify-content-center': !packingResponse.success },
          ]"
        >
          <div
            v-for="(print, index) in types"
            :key="index"
            @click="() => onPrint(print.value)"
            class="col-md-4"
          >
            <button class="btn btn-block btn--export-filter">
              {{ print.name }}
            </button>
          </div>
        </div>
        <hr class="my-10" />
        <div class="d-flex justify-content-center">
          <button @click="toggleModal" class="btn btn--export-filter">
            Done
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  SET_REPORT_MODAL_STATE,
  SET_REPORT_MODAL_DATA,
} from "@/core/services/store/config.module";
// import Swal from "sweetalert2";

export default {
  name: "PrintInvoiceModal",
  props: ["sytemOrderNumber", "types"],
  data: () => ({
    dialog: false,
  }),
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
    onPrint(val) {
      this.$store.commit(SET_PAGE_LOADING, true);
      // let itemSystemCodes = items.map((item) => item.systemId);
      let data = {
        type: val,
        orders: [this.sytemOrderNumber],
        order_type: "b2b",
      };
      ApiService.post("/orders/print", data)
        .then((response) => {
          this.$store.commit(SET_REPORT_MODAL_DATA, {
            total_orders_count: response.data.total_orders_count,
            successful_orders_count: response.data.successful_orders_count,
            failed_orders_count: response.data.failed_orders_count,
            successful_orders: response.data.successful_orders,
            failed_orders: response.data.failed_orders,
            message: response.data.message,
          });
          this.$store.commit(SET_REPORT_MODAL_STATE, true);
          if (response.data.successful_orders_count > 0) {
            if (response.data.path) {
              response.data.path.forEach((element) => {
                const link = document.createElement("a");
                link.href = element;
                link.target = "_blank";
                link.download = "orders.csv";
                link.click();
              });
            }

            // setTimeout(() => {
            // }, 1000);
          }
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      // }
      //  else {
      //   Swal.fire({
      //     title: "Warning",
      //     text: `${name} is not ready for selected orders.`,
      //     icon: "warning",
      //     showConfirmButton: false,
      //   });
      // }
      // } else {
      //   Swal.fire({
      //     title: "Warning",
      //     text: "Please, select at least one order!",
      //     icon: "warning",
      //     showConfirmButton: false,
      //   });
      // }
    },
  },
  computed: {
    packingResponse: function () {
      return this.$store.getters.getWMSPACKINGRespose;
    },
  },
};
</script>
