<template>
  <v-card>
    <v-card-title
      tag="h1"
      class="text--secondary pb-0 ml-5 font-weight-medium font-size-h3 pt-9"
    >
      Picked Orders
    </v-card-title>
    <v-card-text>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        item-key="system_order_number"
        class="my-6 pl-4 pr-8"
        hide-default-footer
        mobile-breakpoint="300"
        :item-class="secondText"
      >
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "OrdersTable",
  data: () => ({
    headers: [
      {
        text: "Order number",
        align: "start",
        sortable: false,
        value: "system_order_number",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
      {
        text: "Client Reference",
        align: "start",
        sortable: false,
        value: "order_client_reference",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
      {
        text: "Number of totes",
        align: "start",
        sortable: false,
        value: "no_of_totes",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
      {
        text: "Status",
        align: "start",
        sortable: false,
        value: "status",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
      {
        text: "Totes",
        align: "start",
        sortable: false,
        value: "totes",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
    ],
  }),
  methods: {
    secondText() {
      return "second-text";
    },
  },
  computed: {
    items: function () {
      return this.$store.getters.getWMSPACKINGOrdersTableData;
    },
  },
  mounted() {},
};
</script>
