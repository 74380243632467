<template>
  <div
    class="ma-0 row-bg-color custom-container-height"
    v-if="!!table_properties"
  >
    <div class="bg-white rounded-lg h-100 py-8 pl-0 px-sm-6 scroll-y">
      <datatable-detail
        class="col-9 col-md-9 col-sm-9"
        :table_properties="table_properties"
      />
      <div
        class="min-w-250px max-w-500px pl-4 mt-8 font-weight-medium w-100 text-capitalize"
      >
        <v-text-field
          v-model="system_order_number"
          label="System Order No."
          type="text"
          autofocus
          outlined
          clearable
          class="poppins rounded-lg"
          append-icon="mdi-barcode-scan"
          :disabled="!selectedWarehouse"
          @click:append="onEnterSystemOrderNo"
          @keyup.enter="onEnterSystemOrderNo"
        />
      </div>
      <DataTable
        v-if="selectedWarehouse"
        :updateTableContent="updateTableContent"
        :togglePickingPackingModal="togglePickingPackingModal"
      />
      <PickingPackingModal ref="pickingPackingModal" />
    </div>
  </div>
</template>

<script>
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import DataTable from "@/own/components/warehouseManagement/B2BPickingPacking/DataTable.vue";
import {
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
  UPDATE_PICKING_PACKING_TASKS,
  SET_SYSTEM_ORDER_NUMBER,
  SET_ORDER_SYSTEM_ID,
} from "@/core/services/store/b2bpickandpack.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
// import ApiService from "@/core/services/api.service";
import PickingPackingModal from "@/own/components/warehouseManagement/B2BPickingPacking/PickingPackingModal.vue";

export default {
  name: "B2BPackingPicking",
  components: {
    DatatableDetail,
    DataTable,
    PickingPackingModal,
  },
  data: () => ({
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
    },
    store_names: {
      getTableFilters: "getB2BPACKINGPICKINGTableFilters",
      getTableState: "getB2BPACKINGPICKINGTableState",
      getTableData: "getB2BPACKINGPICKINGTableData",
      getTableHeaders: "getB2BPACKINGPICKINGTableHeaders",
      getTableProperties: "getB2BPACKINGPICKINGTableProperties",
      getTableExportUrl: "getB2BPACKINGPICKINGExportUrl",
      getTablePermissions: "getB2BPACKINGPICKINGTablePermissions",
    },
    system_order_number: null,
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      if (this.selectedWarehouse) {
        await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
          warehouse_id: this.selectedWarehouse,
        });
      } else {
        this.$store.commit(SET_PAGE_LOADING, false);
      }
    },
    async updateTableContent() {
      this.$store.commit(SET_PAGE_LOADING, true);
      await this.$store
        .dispatch(UPDATE_TABLE_DATA, {
          ...this.$store.getters.getB2BPACKINGPICKINGTableState,
          warehouse_id: this.$store.getters.getSelectedWarehouse,
        })
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    async togglePickingPackingModal(item) {
      const success = await this.$store.dispatch(UPDATE_PICKING_PACKING_TASKS, {
        warehouse_id: this.selectedWarehouse,
        system_order_number: item.system_order_number,
      });

      if (success) {
        this.$store.commit(SET_SYSTEM_ORDER_NUMBER, item.system_order_number);
        this.$store.commit(SET_ORDER_SYSTEM_ID, item.order_system_id);
        this.$refs.pickingPackingModal.toggleModal();
      }
    },
    onEnterSystemOrderNo() {
      if (this.system_order_number) {
        this.togglePickingPackingModal({
          system_order_number: this.system_order_number,
        });
      }
    },
  },
  computed: {
    isDataLoaded: function () {
      return !!this.$store.getters[this.store_names.getTableData];
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    table_properties: function () {
      return this.$store.getters.getB2BPACKINGPICKINGTableProperties;
    },
  },
  beforeMount() {
    this.initTableContent();
  },
  watch: {
    selectedWarehouse: function (val) {
      if (val) {
        this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
          warehouse_id: this.selectedWarehouse,
        });
      }
    },
  },
};
</script>

<style scoped>
.row-bg-color {
  background-color: #eef0f8;
}

.custom-container-height {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 171px) !important;
}

@media (max-width: 1199.98px) {
  .custom-container-height {
    height: calc(100vh - 141px) !important;
  }
}

@media (max-width: 991.98px) {
  .custom-container-height {
    height: calc(100vh - 127px) !important;
  }
}
</style>
