<template>
  <v-data-table
    dense
    type="number"
    :headers="filteredHeaders"
    :items="customItems || items"
    item-key="tote_id"
    class="my-6 pl-4 pr-8"
    hide-default-footer
    mobile-breakpoint="300"
  >
    <template
      v-for="(head, i) in headers"
      v-slot:[`item.`+head.value]="{ item, header, value }"
    >
      <ComponentSelector
        v-bind:key="i"
        :item="item"
        :header="header"
        :value="value"
        class="zIndex-0"
        style="z-index: -1 !important"
      ></ComponentSelector>
    </template>
    <template v-slot:item.description="{ item }">
      <p class="font-size-sm mb-1">
        Design Number:
        <span class="font-weight-regular">{{
          item.sku.design_number || "Unknown"
        }}</span>
      </p>
      <p class="font-size-sm mb-1">
        Size:
        <span class="font-weight-regular">{{
          item.sku.size || "Unknown"
        }}</span>
      </p>
      <p class="font-size-sm mb-1">
        Color:
        <span class="font-weight-regular">{{
          item.sku.color || "Unknown"
        }}</span>
      </p>
    </template>
    <template v-slot:item.wms_status_label="{ item, value }">
      <b-button
        size="sm"
        pill
        :class="{
          'btn--actions': value === 'Picked',
          'iq-btn': value === 'Packed',
        }"
      >
        {{ value }}</b-button
      >
    </template>
  </v-data-table>
</template>

<script>
import ComponentSelector from "@/own/components/datatable/ComponentSelector.vue";

export default {
  name: "PackingTasksTable",
  components: { ComponentSelector },
  props: {
    customItems: { required: false },
    showDescription: { default: true, required: false },
    isMultiple: { default: true, required: false },
    hideStatus: { default: false, required: false },
  },
  computed: {
    headers: function () {
      const columns = [
        {
          text: "Tote",
          align: "start",
          sortable: false,
          value: "tote_id",
          class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
          cellClass: "second-text",
        },
        {
          text: "SKU",
          type: "imageTitleDescription",
          align: "start",
          sortable: false,
          value: "sku",
          class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
          cellClass: "second-text",
        },
        {
          text: "Description",
          align: "start",
          sortable: false,
          value: "description",
          class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
          cellClass: "second-text",
        },
        {
          text: this.isMultiple ? "Pending Quantity" : "Quantity",
          align: "center",
          sortable: false,
          value: "qty",
          class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
          cellClass: "second-text",
          width: 50,
        },
      ];
      if (this.hideStatus) {
        columns.push({
          text: "Status",
          align: "start",
          sortable: false,
          value: "wms_status_label",
          class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
          cellClass: "second-text",
        });
      }
      return columns;
    },
    filteredHeaders: function () {
      if (!this.showDescription && !this.isMultiple) {
        return this.headers.filter((header) => header.value !== "description");
      } else if (!this.isMultiple) {
        return this.headers.filter((header) => header.value !== "actual_qty");
      }
      return this.headers;
    },
    items: function () {
      return this.$store.getters.getWMSPACKINGSKUTasks;
    },
  },
};
</script>
