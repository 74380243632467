<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="900"
    transition="dialog-bottom-transition"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Packing details</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-575px scroll-y mx-5 mx-xl-15 py-7"
      >
        <!--begin::Body-->
        <div v-if="latestItem" class="bg-secondary rounded p-6 mb-8">
          <v-row>
            <v-col cols="auto">
              <v-row class="bg-secondary rounded mt-0">
                <v-col cols="auto" class="font-weight-black py-0 pr-0">
                  Color:
                </v-col>
                <v-col class="py-0">{{
                  latestItem.sku.color || "Unknown"
                }}</v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row class="bg-secondary rounded">
                <v-col cols="auto" class="font-weight-black pr-0">
                  Design Number:
                </v-col>
                <v-col>{{ latestItem.sku.design_number || "Unknown" }}</v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row class="bg-secondary rounded">
                <v-col cols="auto" class="font-weight-black pr-0"> Size </v-col>
                <v-col>{{ latestItem.sku.size || "Unknown" }}</v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row class="bg-secondary rounded">
                <v-col cols="auto" class="font-weight-black pr-0">
                  Description
                </v-col>
                <v-col>{{ latestItem.sku.text2 || "Unknown" }}</v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-text-field
            ref="sku"
            v-model="sku"
            label="Scan SKU"
            type="text"
            autofocus
            outlined
            dense
            clearable
            class="poppins rounded-lg"
            append-icon="mdi-barcode-scan"
            @click:append="enterSku"
            @keyup.enter="enterSku"
          />
        </div>
        <div>
          <v-text-field
            ref="quantity"
            v-model="qty"
            label="Quantity"
            type="number"
            @change="() => validateMinValue('qty', 0)"
            outlined
            dense
            clearable
            class="poppins rounded-lg"
            append-icon="mdi-barcode-scan"
            @click:append="enterQty"
            @keyup.enter="enterQty"
            :disabled="!this.skuEntered.item || !sku"
          />
        </div>
        <packing-tasks-table
          :custom-items="selectedItems"
          :show-description="true"
          :is-multiple="false"
        />
        <v-row class="pt-md-10">
          <v-col cols="6">
            <v-text-field
              v-model="formData.weight"
              label="Weigth (KG)"
              outlined
              dense
              type="number"
              @change="() => validateMinValue('weight', 0, 'formData')"
              clearable
              class="poppins"
              @input="$v.formData.weight.$touch()"
              @blur="$v.formData.weight.$touch()"
              @keyup.enter="$refs.height.focus()"
              :error-messages="weightErrors"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              :value="volume"
              label="Volume"
              outlined
              clearable
              type="number"
              dense
              class="poppins"
              disabled
            />
          </v-col>
          <v-col cols="6" md="4" class="pt-0">
            <v-text-field
              ref="height"
              v-model="formData.height"
              label="Height"
              outlined
              type="number"
              dense
              @change="() => validateMinValue('height', 0, 'formData')"
              clearable
              class="poppins"
              @keyup.enter="$refs.width.focus()"
              @input="$v.formData.height.$touch()"
              @blur="$v.formData.height.$touch()"
              :error-messages="heightErrors"
            />
          </v-col>
          <v-col cols="6" md="4" class="pt-0">
            <v-text-field
              ref="width"
              v-model="formData.width"
              label="Width"
              outlined
              type="number"
              @change="() => validateMinValue('width', 0, 'formData')"
              dense
              clearable
              class="poppins"
              @input="$v.formData.width.$touch()"
              @blur="$v.formData.width.$touch()"
              @keyup.enter="$refs.length.focus()"
              :error-messages="widthErrors"
            />
          </v-col>
          <v-col cols="6" md="4" class="pt-0">
            <v-text-field
              ref="length"
              v-model="formData.length"
              label="Length"
              outlined
              type="number"
              @change="() => validateMinValue('length', 0, 'formData')"
              dense
              clearable
              class="poppins"
              @input="$v.formData.length.$touch()"
              @blur="$v.formData.length.$touch()"
              @keyup.enter="$refs.submit.focus()"
              :error-messages="lengthErrors"
            />
          </v-col>
        </v-row>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="py-6 d-flex flex-grow-1 align-end justify-center">
          <button
            ref="submit"
            @click="submit"
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
          >
            Save
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

// import ApiService from "@/core/services/api.service";
import PackingTasksTable from "@/own/components/warehouseManagement/packing/PackingTasksTable.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  // SET_BATCH_PACKING_BOX,
  // UPDATE_SKU_TASK_QTY,
  SET_VIRTUAL_SKU_TAKS,
  UPDATE_PACKING_STORE,
  // SET_SKU_TASKS,
  UPDATE_BOX_DETAILS,
} from "@/core/services/store/packing2.module";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "BoxDetailsModal",
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      weight: { required },
      height: { required },
      width: { required },
      length: { required },
    },
  },
  components: { PackingTasksTable },
  data: () => ({
    dialog: false,
    sku: null,
    qty: null,
    skuEntered: {
      item: null,
      index: null,
    },
    selectedItems: [
      /****************
      {
        tote_id
        sku
        qty
      }
       ****************/
    ],
    searchedItems: [],
    formData: {
      weight: 1,
      height: 1,
      width: 1,
      length: 1,
    },
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.formData = {
          weight: 1,
          height: 1,
          width: 1,
          length: 1,
        };
        this.selectedItems = [];
        this.searchedItems = [];
        this.sku = null;
        this.qty = null;
        this.$v.$reset();
      } else {
        this.$store.commit(
          SET_VIRTUAL_SKU_TAKS,
          JSON.parse(JSON.stringify(this.skuItems))
        );
      }
      this.dialog = !this.dialog;
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    enterSku() {
      this.skuEntered.item = this.skuTasks.find(
        (item) => item.sku.text1 === this.sku && item.qty > 0
      );
      if (!this.skuEntered.item) {
        Swal.fire({
          title: `Invalid SKU`,
          text: `SKU doesn't exists`,
          icon: "warning",
          showConfirmButton: true,
          focusConfirm: true,
          // timer: 2500,
        });
        return;
      }
      this.searchedItems.push(this.skuEntered.item);
      this.qty = 1;
      if (this.isAutoSaveAllowed) {
        this.enterQty();
        // return;
      } else {
        setTimeout(() => {
          this.$refs.quantity.focus();
        }, 100);
      }
    },
    enterQty() {
      if (this.qty) {
        const item = this.skuEntered.item;
        this.skuEntered.index = this.selectedItems.findIndex(
          (element) =>
            element.sku.text1 === item.sku.text1 &&
            element.tote_id === item.tote_id
        );

        // Quantity must not be greater than expected
        if (this.qty > item.qty) {
          Swal.fire({
            title: `Quantity exceed`,
            text: `Quantity limit exceed for this SKU`,
            icon: "warning",
            showConfirmButton: true,
            // timer: 2500,
          });
          this.qty = null;
          return;
        }

        this.$store.commit(SET_PAGE_LOADING, true);
        const payload = {
          task_id: this.selectedTask.id,
          order_box_id: this.boxInfo.id,
          sku_barcode: this.sku,
          tote_id: this.skuEntered.item.tote_id,
          qty: this.qty,
        };

        this.$store
          .dispatch(UPDATE_PACKING_STORE, payload)
          .then(() => {
            // similar item not added yet
            if (this.skuEntered.index === -1) {
              this.selectedItems.push({ ...item, qty: this.qty });
            }
            // duplicate item
            else {
              const qty = this.selectedItems[this.skuEntered.index].qty;
              this.selectedItems[this.skuEntered.index].qty =
                Number(qty) + Number(this.qty);
            }
            // reset fields
            this.skuEntered = {
              item: null,
              index: null,
            };
            this.sku = null;
            this.qty = null;
            this.$refs.sku.focus();
          })
          // .then(() => {
          //   // Update Packable Quantities table
          //   this.$store.commit(SET_PAGE_LOADING, true);
          //   const payload = {
          //     warehouse_id: this.selectedWarehouse,
          //     tote_id: this.$store.getters.getWMSPACKINGEnteredTote,
          //   };
          //   ApiService.post(`/warehouse_management/packing/show`, payload).then(
          //     ({ data }) => {
          //       this.$store.commit(SET_SKU_TASKS, data.tasks);
          //       this.$store.commit(SET_BATCH_PACKING_BOX, data.boxes);
          //     }
          //   );
          // })
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.$store.commit(SET_PAGE_LOADING, true);
        if (this.selectedItems.length) {
          const payload = {
            system_order_number: this.orderDetails.system_order_number,
            order_box_id: this.boxInfo.id,
            ...this.formData,
          };
          this.$store
            .dispatch(UPDATE_BOX_DETAILS, payload)
            .then(() => {
              this.toggleModal();
            })
            .finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });

          // const skus = this.selectedItems.map((item) => ({
          //   id: item.id,
          //   tote_id: item.tote_id,
          //   sku: item.sku.text1,
          //   packed_qty: item.qty,
          // }));
          // const details = {
          //   ...this.formData,
          //   volume: this.volume,
          // };
          // this.$store.commit(SET_BATCH_PACKING_BOX, { skus, details });
          // this.toggleModal();
          // //set status for packing tasks table
          // skus.map((item) => {
          //   this.$store.commit(UPDATE_SKU_TASK_QTY, item);
          // });
        } else {
          Swal.fire({
            title: `NO SKU selected`,
            text: `No SKU has been added`,
            icon: "warning",
            showConfirmButton: true,
            // timer: 2500,
          }).then(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
            setTimeout(() => {
              this.$refs.quantity.focus();
            }, 500);
          });
        }
      }
    },
  },
  computed: {
    skuItems: function () {
      return this.$store.getters.getWMSPACKINGSKUTasks;
    },
    virtualSkuItems: function () {
      return this.$store.getters.getWMSPACKINGVirtualSKUTasks;
    },
    volume: function () {
      const { height, width, length } = this.formData;
      return height * width * length;
    },
    weightErrors() {
      return this.handleFormValidation("weight", this);
    },
    heightErrors() {
      return this.handleFormValidation("height", this);
    },
    widthErrors() {
      return this.handleFormValidation("width", this);
    },
    lengthErrors() {
      return this.handleFormValidation("length", this);
    },
    latestItem() {
      const len = this.searchedItems.length;
      if (len) {
        return this.searchedItems[len - 1];
      }
      return null;
    },
    skuTasks: function () {
      return this.$store.getters.getWMSPACKINGSKUTasks;
    },
    selectedTask: function () {
      return this.skuTasks.find((item) => item.sku.text1 === this.sku);
    },
    boxInfo: function () {
      return this.$store.getters.getWMSPACKINGBoxInfo;
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    orderDetails: function () {
      return this.$store.getters.getWMSPACKINGOrderDetails;
    },
    isAutoSaveAllowed: function () {
      return this.$store.getters.getWMSPACKINGIsAutoSaveAllowed;
    },
  },
  mounted() {},
};
</script>
