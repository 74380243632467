<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    transition="dialog-bottom-transition"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">{{ title }}</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-575px scroll-y mx-5 mx-xl-15 py-7"
      >
        <!--begin::Body-->
        <div class="bg-secondary p-6 mb-2 rounded-lg mb-6">
          <v-row>
            <v-col md="auto">
              <v-row>
                <v-col cols="auto" class="font-weight-black pr-0">
                  Suggested Location:
                </v-col>
                <v-col>
                  <div>{{ suggestedItem?.location }}</div>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="auto">
              <v-row>
                <v-col cols="auto" class="font-weight-black pr-0">
                  Suggested SKU:
                </v-col>
                <v-col>
                  <div>{{ suggestedItem?.sku.text1 }}</div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="auto" class="font-weight-black pr-0">
                  Description:
                </v-col>
                <v-col>
                  <div>{{ suggestedItem?.sku.text2 }}</div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row>
                <v-col cols="auto" class="font-weight-black pr-0">
                  Design Number:
                </v-col>
                <v-col>
                  <div>{{ suggestedItem?.sku.design_number }}</div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row>
                <v-col cols="auto" class="font-weight-black pr-0">
                  Size:
                </v-col>
                <v-col>
                  <div>{{ suggestedItem?.sku.size }}</div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row>
                <v-col cols="auto" class="font-weight-black pr-0">
                  Color:
                </v-col>
                <v-col>
                  <div>{{ suggestedItem?.sku.color }}</div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-text-field
            ref="location"
            v-model.trim="location"
            label="Location"
            type="type"
            outlined
            :disabled="disabled.location"
            dense
            clearable
            autofocus
            class="poppins rounded-lg"
            append-icon="mdi-barcode-scan"
            @click:append="onEnterLocation"
            @keyup.enter="onEnterLocation"
          />
        </div>
        <div>
          <v-text-field
            ref="sku"
            v-model.trim="sku"
            label="Scan SKU"
            type="text"
            outlined
            dense
            clearable
            :disabled="disabled.sku"
            class="poppins rounded-lg"
            append-icon="mdi-barcode-scan"
            @click:append="onEnterSku"
            @keyup.enter="onEnterSku"
          />
        </div>
        <div>
          <v-text-field
            ref="quantity"
            v-model="qty"
            label="Quantity"
            type="number"
            @change="() => validateMinValue('qty', 0)"
            :disabled="disabled.qty"
            :hint="'Expected qty: ' + this.expected_qty"
            outlined
            min="0"
            dense
            clearable
            class="poppins rounded-lg"
            append-icon="mdi-barcode-scan"
            @click:append="onEnterQuantity"
            @keyup.enter="onEnterQuantity"
          />
        </div>
        <div class="row justify-content-center my-6">
          <div class="col-md-12 d-flex justify-content-around">
            <b-btn
              :disabled="!previousSuggestedItem"
              variant="primary"
              class="mr-3"
              @click="previous"
              >Prev</b-btn
            >
            <b-btn
              v-if="permissionChecker('skip_task')"
              variant="-print-upload"
              class="mr-3"
              @click="skipTask"
              >Skip</b-btn
            >
            <b-btn
              :disabled="!nextSuggestedItem"
              variant="primary"
              @click="next"
              >Next</b-btn
            >
          </div>
        </div>
        <B2BPackingTable :custom-items="selectedItems" />
        <v-row class="pt-md-10">
          <v-col cols="6">
            <v-text-field
              v-model="formData.weight"
              label="Weigth (KG)"
              outlined
              dense
              type="number"
              @change="() => validateMinValue('weight', 0, 'formData')"
              clearable
              class="poppins"
              @input="$v.formData.weight.$touch()"
              @blur="$v.formData.weight.$touch()"
              @keyup.enter="$refs.height.focus()"
              :error-messages="weightErrors"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              :value="volume"
              label="Volume"
              outlined
              clearable
              type="number"
              @change="() => validateMinValue('volume', 0)"
              dense
              class="poppins"
              disabled
            />
          </v-col>
          <v-col cols="6" md="4" class="pt-0">
            <v-text-field
              ref="height"
              v-model="formData.height"
              label="Height"
              outlined
              type="number"
              dense
              @change="() => validateMinValue('height', 0, 'formData')"
              clearable
              class="poppins"
              @keyup.enter="$refs.width.focus()"
              @input="$v.formData.height.$touch()"
              @blur="$v.formData.height.$touch()"
              :error-messages="heightErrors"
            />
          </v-col>
          <v-col cols="6" md="4" class="pt-0">
            <v-text-field
              ref="width"
              v-model="formData.width"
              label="Width"
              outlined
              type="number"
              @change="() => validateMinValue('width', 0, 'formData')"
              dense
              clearable
              class="poppins"
              @input="$v.formData.width.$touch()"
              @blur="$v.formData.width.$touch()"
              @keyup.enter="$refs.length.focus()"
              :error-messages="widthErrors"
            />
          </v-col>
          <v-col cols="6" md="4" class="pt-0">
            <v-text-field
              ref="length"
              v-model="formData.length"
              label="Length"
              outlined
              type="number"
              @change="() => validateMinValue('length', 0, 'formData')"
              dense
              clearable
              class="poppins"
              @input="$v.formData.length.$touch()"
              @blur="$v.formData.length.$touch()"
              @keyup.enter="$refs.submit.focus()"
              :error-messages="lengthErrors"
            />
          </v-col>
        </v-row>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="py-6 d-flex flex-grow-1 align-end justify-center">
          <button
            ref="submit"
            @click="submit"
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
          >
            Save
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <v-snackbar
        v-model="navigationAlert"
        :timeout="navigationAlertTimeout"
        class="mb-10"
      >
        {{ navigationMessage }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="navigationAlert = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
    <!-- <PrintInvoiceModal :sytemOrderNumber="order_id_print" ref="printModal" /> -->
  </v-dialog>
</template>

<script>
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import ApiService from "@/core/services/api.service";
import B2BPackingTable from "@/own/components/warehouseManagement/B2BPickingPacking/B2BPackingTable.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  UPDATE_PICKING_PACKING_TASKS,
  SET_SUGGESTED_ITEM,
} from "@/core/services/store/b2bpickandpack.module";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
// import PrintInvoiceModal from "@/own/components/warehouseManagement/B2BPickingPacking/PrintInvoiceModal.vue";

export default {
  name: "AddBoxModal",
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      weight: { required },
      height: { required },
      width: { required },
      length: { required },
    },
  },
  components: {
    B2BPackingTable,
    // PrintInvoiceModal
  },
  data: () => ({
    dialog: false,
    sku: null,
    location: null,
    qty: null,
    skuEntered: {
      item: null,
      index: null,
    },
    item: {},
    expected_qty: null,
    selectedItems: [
      /****************
      {
        tote_id
        sku.text1
        qty
      }
       ****************/
    ],
    formData: {
      weight: null,
      height: null,
      width: null,
      length: null,
    },
    disabled: {
      location: false,
      sku: true,
      qty: true,
    },
    order_id_print: null,
    exitToHome: false,
    navigationAlert: false,
    navigationMessage: "Navigated to next",
    navigationAlertTimeout: 1500,
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.clearFields();
      } else {
        //set suggested item
        const item = this.pickingPackingTasks.find(
          (item) => item.wms_status_label !== "Packed"
        );
        this.$store.commit(SET_SUGGESTED_ITEM, item);
      }
      this.dialog = !this.dialog;
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    onEnterLocation() {
      if (this.location) {
        if (this.location !== this.suggestedItem.location) {
          Swal.fire({
            title: `Invalid`,
            text: `Location is not valid`,
            icon: "warning",
            showConfirmButton: true,
            timer: 2500,
          });
          return;
        }
        this.disabled.sku = false;
        //check locked location
        this.$store.commit(SET_PAGE_LOADING, true);
        const payload = {
          warehouse_id: this.selectedWarehouse,
          location: this.location,
        };
        ApiService.post(
          `/warehouse_management/warehouses/check_locked_location`,
          payload
        )
          .then(() => {
            this.locationValidated = true;
            setTimeout(() => {
              this.$refs.sku.focus();
            }, 100);
          })
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      }
    },
    onEnterSku() {
      const hasMatchingSku = () => this.sku === this.suggestedItem.sku.text1;
      const alert = () => {
        Swal.fire({
          title: `Invalid`,
          text: `SKU is not valid`,
          icon: "warning",
          showConfirmButton: true,
          timer: 2500,
        });
      };
      const focusQty = () =>
        setTimeout(() => {
          this.$refs.quantity.focus();
        }, 50);

      const mutateFields = () => {
        const item = this.suggestedItem;

        this.disabled.qty = false;
        this.item = {
          task_id: item.id,
          order_box_id: this.boxInfo.id,
          sku_barcode: this.sku,
          location: this.location,
        };
        this.expected_qty = item.left_packed_qty;
      };

      if (this.sku) {
        if (!hasMatchingSku()) {
          if (this.isCheckWithMaster) {
            this.$store.commit(SET_PAGE_LOADING, true);

            const payload = {
              warehouse_id: this.selectedWarehouse,
              task_id: this.suggestedItem.id,
              sku_barcode: this.sku,
            };

            ApiService.post(
              `warehouse_management/b2b_pick_and_pack/validate_sku`,
              payload
            )
              .then(() => {
                focusQty();
                mutateFields();
              })
              .finally(() => {
                this.$store.commit(SET_PAGE_LOADING, false);
              });
          } else {
            alert();
          }
        } else {
          mutateFields();
          focusQty();
        }
      }
    },
    updatePackingTasks() {
      let found = false;
      this.selectedItems.map((item) => {
        if (
          item.location === this.item.location &&
          item.sku_barcode === this.item.sku_barcode
        ) {
          item.qty = Number(item.qty) + Number(this.item.qty);
          found = true;
        }
      });
      if (!found) {
        this.selectedItems.unshift(this.item);
      }
      found = false;
      this.item = {};
      const suggestedItemIndex = this.pickingPackingTasks.indexOf(
        this.suggestedItem
      );
      this.$store
        .dispatch(UPDATE_PICKING_PACKING_TASKS, {
          warehouse_id: this.selectedWarehouse,
          system_order_number:
            this.$store.state.B2BPickAndPack.system_order_number,
        })
        .then(() => {
          // Since two different item with same location & sku may exists
          const suggested_item = this.pickingPackingTasks[suggestedItemIndex];
          this.$store.commit(SET_SUGGESTED_ITEM, suggested_item);
          this.expected_qty = suggested_item?.left_packed_qty;
          if (this.expected_qty === 0) {
            if (this.nextSuggestedItem) {
              this.next();
            } else {
              if (this.firstPendingItem) {
                this.$store.commit(SET_SUGGESTED_ITEM, this.firstPendingItem);
                this.navigationAlertTimeout = 1500;
                this.navigationAlert = true;
                this.navigationMessage = "Navigated to unpacked item";
              } else {
                this.navigationAlert = true;
                this.navigationMessage = "All items are packed.";
                this.navigationAlertTimeout = 5000;
                this.clearAddBoxFields();
              }
            }
          }
          this.clearAddBoxFields();

          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    async onEnterQuantity() {
      if (this.qty > this.expected_qty) {
        Swal.fire({
          title: `Invalid quantity`,
          text: `Quantity limite exceeds.`,
          icon: "warning",
          showConfirmButton: true,
          timer: 2500,
        });
      } else {
        this.item = {
          ...this.item,
          qty: this.qty,
          warehouse_id: this.selectedWarehouse,
        };
        this.$store.commit(SET_PAGE_LOADING, true);
        await ApiService.post(
          `warehouse_management/b2b_pick_and_pack/store_b2b_picking`,
          this.item
        ).then(() => {
          Swal.fire({
            title: `Success`,
            text: `Packing updated successfully!`,
            icon: "success",
            // showConfirmButton: true,
            timer: 2500,
          });
          // this.selectedItems.unshift(this.item);
          this.updatePackingTasks();
        });
      }
    },
    clearAddBoxFields() {
      this.sku = null;
      this.location = null;
      this.qty = null;
      this.disabled = {
        location: false,
        sku: true,
        qty: true,
      };
    },
    skipTask() {
      this.$store.commit(SET_PAGE_LOADING, true);
      const payload = {
        task_id: this.suggestedItem.id,
      };

      ApiService.post(
        `/warehouse_management/b2b_pick_and_pack/skip_task`,
        payload
      ).finally(() => {
        this.updatePackingTasks();
        this.$store.commit(SET_PAGE_LOADING, true);
      });
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.$store.commit(SET_PAGE_LOADING, true);
        if (
          (this.selectedItems.length && this.actionType === "create") ||
          this.actionType === "edit"
        ) {
          const payload = {
            system_order_number:
              this.$store.state.B2BPickAndPack.system_order_number,
            order_box_id: this.boxInfo.id,
            ...this.formData,
          };
          ApiService.post(
            `/warehouse_management/b2b_pick_and_pack/update`,
            payload
          )
            .then(({ data }) => {
              this.$store
                .dispatch(UPDATE_PICKING_PACKING_TASKS, {
                  warehouse_id: this.selectedWarehouse,
                  system_order_number:
                    this.$store.state.B2BPickAndPack.system_order_number,
                })
                .then(() => {
                  Swal.fire({
                    title: `Success`,
                    text: `Packing Created`,
                    icon: "success",
                    showConfirmButton: true,
                    timer: 2500,
                  });
                  this.toggleModal();
                });

              if (data.is_fully_packed) {
                this.order_id_print = data.order_id;
                // this.$refs.printModal.toggleModal();
              }
            })
            .finally(() => {
              // this.$store.commit(SET_PAGE_LOADING, false);
            });
        } else {
          Swal.fire({
            title: `Invalid`,
            text: `No items has been added`,
            icon: "warning",
            showConfirmButton: true,
            // timer: 2500,
          });
          this.$store.commit(SET_PAGE_LOADING, false);
        }
      }
    },
    setFormData(data) {
      const { height, width, length, weight } = data;
      this.formData = {
        height,
        width,
        length,
        weight,
      };
    },
    clearFields() {
      this.sku = null;
      this.location = null;
      this.qty = null;
      this.skuEntered = {
        item: null,
        index: null,
      };
      this.item = {};
      this.expected_qty = null;
      this.selectedItems = [];
      this.setFormData(this.boxInfo);
      this.disabled = {
        sku: true,
        qty: true,
      };
    },
    previous() {
      if (this.previousSuggestedItem) {
        this.$store.commit(SET_PAGE_LOADING, true);
        setTimeout(() => {
          this.$store.commit(SET_SUGGESTED_ITEM, this.previousSuggestedItem);
          this.$store.commit(SET_PAGE_LOADING, false);
          this.navigationAlertTimeout = 1500;
          this.navigationAlert = true;
          this.navigationMessage = "Navigated to previous item";
        }, 300);
        this.clearAddBoxFields();
        this.$refs.location.focus();
      }
    },
    next() {
      if (this.nextSuggestedItem) {
        this.$store.commit(SET_PAGE_LOADING, true);
        setTimeout(() => {
          this.$store.commit(SET_SUGGESTED_ITEM, this.nextSuggestedItem);
          this.$store.commit(SET_PAGE_LOADING, false);
          this.navigationAlertTimeout = 1500;
          this.navigationAlert = true;
          this.navigationMessage = "Navigated to next item";
        }, 300);
        this.clearAddBoxFields();
        this.$refs.location.focus();
      }
    },
    permissionChecker(permType) {
      return !!this.table_permissions.includes(permType);
    },
  },
  computed: {
    pickingPackingTasks: function () {
      return this.$store.getters.getB2BPACKINGPICKINGTasksData;
    },
    skuItems: function () {
      return this.$store.getters.getWMSPACKINGSKUTasks;
    },
    virtualSkuItems: function () {
      return this.$store.getters.getWMSPACKINGVirtualSKUTasks;
    },
    volume: function () {
      const { height, width, length } = this.formData;
      return height * width * length;
    },
    weightErrors() {
      return this.handleFormValidation("weight", this);
    },
    heightErrors() {
      return this.handleFormValidation("height", this);
    },
    widthErrors() {
      return this.handleFormValidation("width", this);
    },
    lengthErrors() {
      return this.handleFormValidation("length", this);
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    boxInfo: function () {
      return this.$store.getters.getB2BPACKINGPICKINGCreateBoxInfo;
    },
    noOfValidTaks: function () {
      const data = this.$store.getters.getB2BPACKINGPICKINGTasksData;
      // return data;
      return data.filter((item) => item.left_picked_qty).length;
    },
    suggestedItem: function () {
      return this.$store.getters.getB2BPACKINGPICKINGSuggestedItem;
    },
    nextSuggestedItem: function () {
      const currentIndex = this.pickingPackingTasks.indexOf(this.suggestedItem);
      if (currentIndex !== -1) {
        const rest = this.pickingPackingTasks.slice(currentIndex + 1);
        return rest.find((item) => item.wms_status_label !== "Packed");
      }
      return null;
    },
    previousSuggestedItem: function () {
      const currentIndex = this.pickingPackingTasks.indexOf(this.suggestedItem);
      if (currentIndex !== -1) {
        if (currentIndex === 0) {
          return null;
        }
        const rest = this.pickingPackingTasks.slice(0, currentIndex);
        return rest.findLast((item) => item.wms_status_label !== "Packed");
      }
      return null;
    },
    firstPendingItem: function () {
      return this.pickingPackingTasks.find(
        (item) => item.wms_status_label !== "Packed"
      );
    },
    title: function () {
      if (this.$store.getters.getB2BPACKINGPICKINGAddBoxActionType === "edit") {
        return "Edit Box";
      }
      return "Add Box";
    },
    actionType: function () {
      return this.$store.getters.getB2BPACKINGPICKINGAddBoxActionType;
    },
    table_permissions: function () {
      if (this.$store.getters.getB2BPACKINGPICKINGTablePermissions) {
        return this.$store.getters.getB2BPACKINGPICKINGTablePermissions.map(
          function (perm) {
            return perm.name;
          }
        );
      } else {
        return false;
      }
    },
    isCheckWithMaster: function () {
      return this.$store.getters.getB2BPACKINGPICKINGIsCheckWithMaster;
    },
  },
  mounted() {},
  watch: {
    boxInfo: function (val) {
      this.setFormData(val);
    },
  },
};
</script>
