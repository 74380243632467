<template>
  <v-dialog
    v-model="dialog"
    persistent
    content-class="ml-0 mr-n16 my-0 custom-dialog-height"
    transition="dialog-bottom-transition"
    fullscreen
  >
    <div class="row-bg-color pr-0 poppins custom-body-height">
      <div class="modal-header h-60px bg-white align-center">
        <h3 class="mb-0 font-weight-bolder">B2B Picking & Packing</h3>
        <div>
          <!-- <span class="svg-icon">
            <v-icon size="20">mdi-close</v-icon>
          </span> -->
        </div>
      </div>
      <div
        class="modal-body custom-container-height d-flex flex-column ml-3 mr-0 py-7"
      >
        <v-row class="ma-0 h-100">
          <v-col cols="6" class="custom-right-border pa-0 h-100">
            <div class="bg-white h-100 custom-overflow rounded-lg pa-4 pt-0">
              <h3 class="mb-0 font-weight-bolder my-5 mb-10 ml-5 mt-8">
                Packing Tasks
              </h3>
              <div class="sticky-header bg-white pt-1">
                <div class="bg-secondary p-6 mb-2 rounded-lg mx-7 mb-12">
                  <v-row>
                    <v-col md="auto">
                      <v-row>
                        <v-col cols="auto" class="font-weight-black pr-0">
                          Order Status:
                        </v-col>
                        <v-col>
                          <!-- <div>{{ orderSummary?.order_status }}</div> -->
                          <div>
                            <b-badge
                              pill
                              :variant="
                                orderSummary?.order_status ===
                                'Partially Packed'
                                  ? 'primary'
                                  : 'dark'
                              "
                              >{{ orderSummary?.order_status }}</b-badge
                            >
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="auto">
                      <v-row>
                        <v-col cols="auto" class="font-weight-black pr-0">
                          Total qty:
                        </v-col>
                        <v-col>
                          <div>{{ orderSummary?.total_qty }}</div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col md="auto">
                      <v-row>
                        <v-col cols="auto" class="font-weight-black pr-0">
                          Left packed qty:
                        </v-col>
                        <v-col>
                          <div>{{ orderSummary?.left_packed_qty }}</div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="auto">
                      <v-row>
                        <v-col cols="auto" class="font-weight-black pr-0">
                          Total packed qty:
                        </v-col>
                        <v-col>
                          <div>{{ orderSummary?.total_packed_qty }}</div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div class="">
                <v-data-table
                  item-key="id"
                  :item-class="secondText"
                  :headers="taskTableheaders"
                  :items="tasksData"
                  :items-per-page="50"
                  :footer-props="{
                    'items-per-page-options': [100, 50, 40, 30, 20],
                  }"
                  dense
                  disable-sort
                  class="my-6 pl-4 pr-8"
                  mobile-breakpoint="300"
                ></v-data-table>
              </div>
            </div>
          </v-col>
          <v-col cols="6" class="custom-left-border pa-0 h-100">
            <div class="bg-white h-100 custom-overflow rounded-lg pa-4 pt-0">
              <div class="mb-10">
                <div class="position-relative">
                  <div class="d-flex justify-content-center">
                    <div class="zindex-4 bg-white px-8">
                      <button
                        class="btn btn--export-filter btn-sm py-3 mt-5"
                        @click="toggleAddBoxModal"
                      >
                        <span class="svg-icon mr-0 pr-0">
                          <v-icon size="20">mdi-package-variant</v-icon>
                        </span>
                        Add Box
                      </button>
                    </div>
                  </div>
                  <hr class="position-absolute w-100 divider" />
                </div>
                <h3 class="mb-0 font-weight-bolder my-5 ml-5">Boxes</h3>
                <div class="table__wrapper">
                  <v-data-table
                    item-key="id"
                    :item-class="secondText"
                    :headers="boxTableheaders"
                    :items="boxesData"
                    :items-per-page="10"
                    dense
                    disable-sort
                    class="my-6 pl-4 pr-8"
                    mobile-breakpoint="300"
                  >
                    <template #item.action="{ item, header, value }">
                      <b-btn
                        @click="editBox(item)"
                        variant="primary"
                        size="sm"
                        class="mr-3"
                        >Edit</b-btn
                      >
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <b-btn
                            variant="info"
                            size="sm"
                            v-bind="attrs"
                            v-on="on"
                            >Print</b-btn
                          >
                        </template>
                        <v-list>
                          <v-list-item
                            dense
                            v-for="(print_type, index) in print_types"
                            :key="index"
                            @click="printBarcode(item, print_type.index)"
                          >
                            <v-list-item-title>{{
                              print_type.text
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div
        class="modal-footer bg-white h-60px py-0 pr-10 d-flex align-center justify-end"
      >
        <button
          @click="() => $refs.printInvoiceModal.toggleModal()"
          class="btn btn-primary"
          v-if="types.length > 0"
        >
          Print
        </button>
        <button @click="toggleModal" class="btn btn-info">Save & Exit</button>
      </div>
    </div>
    <component
      v-bind:is="printInvoiceModal"
      v-if="types.length > 0"
      ref="printInvoiceModal"
      :sytemOrderNumber="systemOrderNumber"
      :types="types"
    ></component>
    <AddBoxModal ref="addBoxModal" />
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";

import AddBoxModal from "@/own/components/warehouseManagement/B2BPickingPacking/AddBoxModal.vue";
import PrintInvoiceModal from "@/own/components/warehouseManagement/B2BPickingPacking/PrintInvoiceModal.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  UPDATE_CREATE_BOX_DATA,
  UPDATE_TABLE_DATA,
  SET_ADD_BOX_ACTION_TYPE,
  UPDATE_EDIT_BOX_DATA,
} from "@/core/services/store/b2bpickandpack.module";
import Swal from "sweetalert2";

export default {
  name: "PickingPackingModal",
  props: [],
  components: { AddBoxModal },
  data: () => ({
    dialog: false,
    print_types: [
      { text: "ZPL", index: "zpl" },
      { text: "PDF", index: "pdf" },
    ],
    // addBoxModal: AddBoxModal,
    printInvoiceModal: PrintInvoiceModal,
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.$store.dispatch(UPDATE_TABLE_DATA, {
          warehouse_id: this.$store.getters.getSelectedWarehouse,
        });
      }
      this.dialog = !this.dialog;
    },
    secondText() {
      return "second-text";
    },
    toggleAddBoxModal() {
      if (this.tasksData.length) {
        this.$store.commit(SET_ADD_BOX_ACTION_TYPE, "create");
        const system_order_number =
          this.$store.state.B2BPickAndPack.system_order_number;
        this.$store
          .dispatch(UPDATE_CREATE_BOX_DATA, {
            system_order_number,
          })
          .then(() => {
            this.$refs.addBoxModal.toggleModal();
          });
      } else
        Swal.fire({
          title: "Warning",
          text: "Packing task is empty",
          icon: "warning",
          showConfirmButton: true,
          confirmButtonColor: "#8950FC",
          confirmButtonText: "Ok",
        });
    },
    printBarcode(item, print_type) {
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = { id: item.id, type: print_type };
      ApiService.post("/warehouse_management/b2b_pick_and_pack/print", data)
        .then((response) => {
          const link = document.createElement("a");
          link.href = response.data.path;
          link.target = "_blank";
          // link.download = "orders.csv";
          link.click();
          setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          }, 2500);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    async editBox(item) {
      const payload = {
        warehouse_id: this.$store.getters.getSelectedWarehouse,
        system_order_number:
          this.$store.getters.getB2BPACKINGPICKINGSystemOrderNumber,
        order_box_id: item.id,
      };
      this.$store.commit(SET_ADD_BOX_ACTION_TYPE, "edit");
      this.$store.state.B2BPickAndPack.system_order_number;
      await this.$store.dispatch(UPDATE_EDIT_BOX_DATA, payload).then(() => {
        this.$refs.addBoxModal.toggleModal();
      });
    },
  },
  computed: {
    types: function () {
      return this.$store.getters.getB2BPACKINGPICKINGPrintTypes;
    },
    taskTableheaders: function () {
      return [
        {
          text: "SKU",
          value: "sku.text1",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Location",
          value: "location",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Quantity",
          value: "qty",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Left packed Quantity",
          value: "left_packed_qty",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Packed Quantity",
          value: "packed_qty",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Status",
          value: "wms_status_label",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
      ];
    },
    boxTableheaders: function () {
      return [
        {
          text: "Box Number",
          value: "box_no",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Number of Master SKUs",
          value: "no_of_skus",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Number of Alt SKUs",
          value: "no_of_alt_skus",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Total SKU Quantities",
          value: "total_sku_quantites",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Weight",
          value: "weight",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Length",
          value: "length",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Width",
          value: "width",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Height",
          value: "height",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Volume",
          value: "volume",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Action",
          value: "action",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
      ];
    },
    tasksData: function () {
      return this.$store.getters.getB2BPACKINGPICKINGTasksData;
    },
    boxesData: function () {
      return this.$store.getters.getB2BPACKINGPICKINGBoxesData;
    },
    systemOrderNumber: function () {
      return this.$store.getters.getB2BPACKINGPICKINGOrderSystemId;
    },
    orderSummary: function () {
      return this.$store.getters.getB2BPACKINGPICKINGOrderSummary;
    },
  },
  mounted() {},
};
</script>
<style scoped>
.row-bg-color {
  background-color: #eef0f8;
}

.custom-right-border {
  border-right: 14px solid #eef0f8;
}

.custom-left-border {
  border-left: 14px solid #eef0f8;
}

.custom-overflow {
  overflow-y: scroll;
  position: relative;
}

.custom-body-height {
  height: 100vh !important;
}

.custom-container-height {
  height: calc(100vh - 120px) !important;
}

@media (max-width: 1199.98px) {
  .custom-container-height {
    height: calc(100vh - 141px) !important;
  }
}

@media (max-width: 991.98px) {
  .custom-container-height {
    height: calc(100vh - 127px) !important;
  }
}

.custom-overflow::-webkit-scrollbar {
  width: 12px !important;
}
.custom-overflow::-webkit-scrollbar-thumb {
  border-radius: 12px !important;
}
.divider {
  bottom: 10px;
  background-color: gainsboro;
}
.table__wrapper {
  max-height: 500px;
  overflow: auto;
}
.sticky-header {
  position: sticky;
  top: 0px;
}
</style>
