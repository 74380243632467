<template>
  <div class="mt-6">
    <v-data-table
      fixed-header
      light
      dense
      :items="items"
      item-key="asn_number"
      :server-items-length="items.length"
      :headers="headers"
      :items-per-page="itemPerPage"
      hide-default-footer
      mobile-breakpoint="300"
      @dblclick:row="doubleClickRow"
    >
      <!--    no-data-->
      <template v-slot:no-data> NO DATA HERE! </template>
      <template v-slot:no-results> NO RESULTS HERE! </template>
      <template
        v-for="(head, i) in headers"
        v-slot:[`item.`+head.value]="{ item, header, value }"
      >
        <ComponentSelector
          v-bind:key="i"
          :item="item"
          :header="header"
          :value="value"
        ></ComponentSelector>
      </template>

      <template #item.action="{ item, value }">
        <v-btn
          class="btn iq-btn poppins mr-2"
          @click="() => togglePickingPackingModal(item)"
        >
          Scan
        </v-btn>
      </template>
    </v-data-table>
    <Pagination
      :setitemPerPage="setItemPerPage"
      :getitemPerPage="getItemPerPage"
      :getpageNumber="getPageNumber"
      :setpageNumber="setPageNumber"
      :totalItems="totalItems"
      :pageCount="pageCount"
      :updateTableContent="updateTableContent"
    ></Pagination>
  </div>
</template>

<script>
import ComponentSelector from "@/own/components/datatable/ComponentSelector";
import Pagination from "@/own/components/pagination/Pagination";
import {
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
} from "@/core/services/store/b2bpickandpack.module";

export default {
  name: "DataTable",
  props: ["updateTableContent", "togglePickingPackingModal"],
  components: { Pagination, ComponentSelector },
  methods: {
    setItemPerPage(val) {
      this.$store.commit(SET_TABLE_PERPAGE, val);
      this.$store.commit(SET_TABLE_PAGE, 1);
    },
    setPageNumber(val) {
      this.$store.commit(SET_TABLE_PAGE, val);
    },
    doubleClickRow(_, item) {
      this.togglePickingPackingModal(item.item);
    },
  },
  computed: {
    //pagination
    getItemPerPage: function () {
      return this.$store.getters.getB2BPACKINGPICKINGTableState.per_page;
    },
    getPageNumber: function () {
      return this.$store.getters.getB2BPACKINGPICKINGTableState.page;
    },
    totalItems: function () {
      return this.$store.getters.getB2BPACKINGPICKINGTableData?.total;
    },
    pageCount: function () {
      return this.$store.getters.getB2BPACKINGPICKINGTableData?.last_page;
    },

    items: function () {
      let items = [];
      if (this.$store.getters.getB2BPACKINGPICKINGTableData) {
        items = this.$store.getters.getB2BPACKINGPICKINGTableData?.data;
      }
      return items;
    },
    itemPerPage: function () {
      return this.$store.getters.getB2BPACKINGPICKINGTableState.per_page;
    },

    headers: function () {
      return this.$store.getters.getB2BPACKINGPICKINGTableHeaders;
    },
  },
};
</script>
