<template>
  <div>
    <v-card>
      <v-card-title
        tag="h1"
        class="text--secondary pb-0 ml-5 font-weight-medium font-size-h3 pt-9"
      >
        Packing tasks
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="11" class="mt-5 mx-5">
            <div class="bg-secondary rounded p-6">
              <v-row>
                <v-col ccols="5" md="6" xl="5">
                  <v-row class="bg-secondary font-size-sm rounded mt-0">
                    <v-col cols="auto" class="font-weight-black py-0 pr-0">
                      System Order Number:
                    </v-col>
                    <v-col class="py-0">{{
                      orderDetails?.system_order_number || "Unknown"
                    }}</v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row class="bg-secondary font-size-sm rounded">
                    <v-col cols="auto" class="font-weight-black pr-0">
                      Client Reference:
                    </v-col>
                    <v-col
                      >{{ orderDetails?.order_client_reference || "Unknown" }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row class="bg-secondary font-size-sm rounded">
                    <v-col cols="auto" class="font-weight-black pr-0">
                      Status:
                    </v-col>
                    <v-col>{{ orderDetails?.status || "Unknown" }} </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col cols="12">
            <packing-tasks-table :is-multiple="false" />
          </v-col>
        </v-row>
        <hr />
        <v-row class="pt-md-10">
          <v-col cols="6">
            <v-text-field
              v-model="formData.weight"
              label="Weigth (KG)"
              outlined
              dense
              type="number"
              @change="() => validateMinValue('weight', 0, 'formData')"
              clearable
              class="poppins"
              @input="$v.formData.weight.$touch()"
              @blur="$v.formData.weight.$touch()"
              @keyup.enter="$refs.height.focus()"
              :error-messages="weightErrors"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              :value="volume"
              label="Volume"
              outlined
              clearable
              type="number"
              dense
              class="poppins"
              disabled
            />
          </v-col>
          <v-col cols="6" md="4" class="pt-0">
            <v-text-field
              ref="height"
              v-model="formData.height"
              label="Height"
              outlined
              type="number"
              @change="() => validateMinValue('height', 0, 'formData')"
              dense
              clearable
              class="poppins"
              @input="$v.formData.height.$touch()"
              @blur="$v.formData.height.$touch()"
              @keyup.enter="$refs.width.focus()"
              :error-messages="heightErrors"
            />
          </v-col>
          <v-col cols="6" md="4" class="pt-0">
            <v-text-field
              ref="width"
              v-model="formData.width"
              label="Width"
              outlined
              type="number"
              @change="() => validateMinValue('width', 0, 'formData')"
              dense
              clearable
              class="poppins"
              @input="$v.formData.width.$touch()"
              @blur="$v.formData.width.$touch()"
              @keyup.enter="$refs.length.focus()"
              :error-messages="widthErrors"
            />
          </v-col>
          <v-col cols="6" md="4" class="pt-0">
            <v-text-field
              ref="length"
              v-model="formData.length"
              label="Length"
              outlined
              type="number"
              dense
              @change="() => validateMinValue('length', 0, 'formData')"
              clearable
              class="poppins"
              @input="$v.formData.length.$touch()"
              @blur="$v.formData.length.$touch()"
              @keyup.enter="$refs.submit.focus()"
              :error-messages="lengthErrors"
            />
          </v-col>
        </v-row>
        <!--begin::Actions-->
        <div class="py-6 d-flex flex-grow-1 align-end justify-center">
          <button
            @click="submit"
            type="submit"
            class="btn btn-info px-9 py-3 ls1 mr-3"
          >
            Submit
          </button>
          <button
            @click="clearData"
            type="submit"
            class="btn btn--actions px-9 py-3 ls1"
          >
            Close
          </button>
        </div>
        <!--end::Actions-->
      </v-card-text>
    </v-card>
    <component
      ref="invoice_modal"
      :refresher="refresher"
      v-bind:is="invoiceModal"
      @on-close-print="onClosePrint"
    ></component>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

import {
  UPDATE_PACKING_STORE,
  UPDATE_BOX_DETAILS,
} from "@/core/services/store/packing2.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import PackingTasksTable from "@/own/components/warehouseManagement/packing/PackingTasksTable.vue";
import InvoiceModal from "@/own/components/warehouseManagement/packing/InvoiceModal.vue";

export default {
  name: "SinglePacking",
  mixins: [validationMixin],
  props: ["refresher", "tote", "sku"],
  validations: {
    formData: {
      weight: { required },
      height: { required },
      width: { required },
      length: { required },
    },
  },
  components: { PackingTasksTable },
  data: () => ({
    invoiceModal: InvoiceModal,
    formData: {
      weight: 1,
      height: 1,
      width: 1,
      length: 1,
    },
  }),
  methods: {
    secondText() {
      return "second-text";
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submit() {
      // store b2b packing
      this.$store.commit(SET_PAGE_LOADING, true);
      const payload = {
        task_id: this.skuTask.id,
        order_box_id: this.boxInfo.id,
        sku_barcode: this.sku,
        tote_id: this.tote,
        qty: this.skuTask.qty,
      };

      this.$store
        .dispatch(UPDATE_PACKING_STORE, payload)
        .then(() => {
          const reqPayload = {
            system_order_number: this.orderDetails.system_order_number,
            order_box_id: this.boxInfo.id,
            ...this.formData,
          };
          this.$store.dispatch(UPDATE_BOX_DETAILS, reqPayload);
        })
        .then(() => {
          Swal.fire({
            title: `Success`,
            text: `Order Packed`,
            icon: "success",
            confirmButtonClass: "btn btn-primary",
            showConfirmButton: true,
            timer: 2500,
          });
        })
        .then(() => {
          this.$refs.invoice_modal.toggleModal();
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    onClosePrint() {
      this.$emit("on-close-print");
    },
    clearData() {
      this.refresher();
    },
  },
  computed: {
    volume: function () {
      const { height, width, length } = this.formData;
      return height * width * length;
    },
    weightErrors() {
      return this.handleFormValidation("weight", this);
    },
    heightErrors() {
      return this.handleFormValidation("height", this);
    },
    widthErrors() {
      return this.handleFormValidation("width", this);
    },
    lengthErrors() {
      return this.handleFormValidation("length", this);
    },
    skuTask() {
      return this.$store.getters.getWMSPACKINGSKUTasks[0];
    },
    orderDetails: function () {
      return this.$store.getters.getWMSPACKINGOrderDetails;
    },
    boxInfo: function () {
      return this.$store.getters.getWMSPACKINGBoxInfo;
    },
  },
};
</script>
